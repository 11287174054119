.noClassesPage, .myclassroomPage, .checkOutPage, .studentClassesPage {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: #EEF4F6;
}
.no-classes-sec{
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 64px 0;
}
.classheading{
    color: #1F1F39;
    font-size: 28px;
    font-weight: 600;
    margin: 20px 0;
}
.noClasscard {
    max-width: 600px;
    background: #fff;
    margin: 32px auto;
    padding: 59px;
    text-align: center;
}
.noClasscard .cardTitle{
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 32px;
}
.noClasscard .cardPara{
    color: #767676;  
    font-size: 16px;  
    font-weight: 400;   
}
.noClasscard .cardButton {
    background: #20C868;
    font-size: 16px;
    font-weight: 700;
    color: #fff;   
    max-width: 300px;
    width: 100%;
    border: 0;
    padding:13px;
    margin-top: 20px;
}
.addClassPopUP {
    position: fixed;
    background: rgba(0, 0, 0, 0.80);
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
}
.addClassPopUP .addclasspopUpCard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    max-width: 625px;
    width: 100%;    
}
.addClassCard .card-Heading {
    color: #1F1F39;    
    font-size: 22px;    
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}
.addclasspopUpCard .addClassCard{
    position: relative;
}
.addclasspopUpCard .addClassCard .closeBtn {
    position: absolute;
    right: -27px;
    width: 25px;
    height: 25px;
    background: transparent;
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border-radius: 50%;
    padding: 2px;
    display: flex;
    justify-content: center;
}
.addclasspopUpCard .addClassCard .card-body{
    padding: 32px 35px;
}
.addclasspopUpCard .addClassCard .cardFooter{
    padding: 20px 35px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    gap: 10px;
}
.addClassCard form{
    width: 100%;
}
.addClassCard .formControl{
    width: 100%;
    margin-bottom: 25px;
}
.addClassCard .formControl label{
    display: block;
    margin-bottom: 5px;
    color: #1F1F39;    
    font-size: 16px;    
    font-weight: 600;    
}
.addClassCard .formControl .forminput{
    width: 100%;
    padding: 10px 16px;
    background: #EEF4F6;
    border: 0;
    outline: 0;
}
.addClassCard .cardFooter button{
    max-width: 180px;
    width: 100%;
    padding: 10px;
    border: 0;
    font-size: 16px;
    background: transparent;
    font-weight: 600;
}
.addClassCard .cardFooter .cancleButton{
    border: 1px solid #1F1F39;
    color: #1F1F39;
}
.addClassCard .cardFooter .addClassButton{
    background: #20C868;
    color: #fff;
}
.myClassRoomInner{
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 65px 0;
}
.myClassRoomSec{
    width: 100%;
}
.myClassroomCard{
    background-color: #fff;
    max-width: 800px;
    width: 100%;
    padding: 25px;
    margin-bottom: 20px;
}
.myClassRoomInner .classheading{
    margin: 0;
}
.myClassRoomInner .titleSec{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.myClassRoomInner .titleSec button{
    background-color: transparent;
    border: 1px solid #23CE6B;
    color: #23CE6B;   
    font-size: 18.254px;    
    font-weight: 500;
    max-width: 100px;
    width: 100%;
}
.cardHeading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.cardHeading button{
    background-color: transparent;
    border: 0;
    cursor: pointer;
}
.cardHeading .SubjectTitle h3{
    color: #1F1F39;    
    font-size: 20px;  
    font-weight: 600;
    line-height: 24px;
    display: inline-block;
}
.myClassroomCard p{
    color: #1F1F39;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.cardHeading .SubjectTitle {
    margin-bottom: 10px;
}
.myClassroomCard .cardbody {
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.myClassroomCard .cardbody .leftcontent .classData{
    display: flex;
    justify-content: space-between;
}
.myClassroomCard .cardbody .classLinkSec{
    display: flex;
    margin: 10px 0 0;
    align-items: center;
}
.myClassroomCard .rightcontent .startBtn {
    background: #20C868;
    padding: 8px 12px;
    border: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    min-width: 110px;
    width: 100%;
    color: #fff;
}
.classLinkSec a.classlink{
    border-radius: 4px;
    background: #EEF4F6;
    padding: 5px 7px;
    margin: 0 5px;
    color: #0D99FF;    
    font-size: 14px;    
    font-weight: 400;
}
button.copyButton , button.sharebtn{
    border-radius: 4px;
    background: #EEF4F6;
    font-size: 22px;
    padding: 6px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    margin: 0 5px ;
}

.checkoutPageSec {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 64px 0;
}
.checkoutCard {
    max-width: 600px;
    background: #fff;
    margin: 0 auto;
    width: 100%;
}
.checkoutCard .card-body {
    padding: 25px;
}
.paymentHeading h2{
    color: #1F1F39;   
    font-size: 20px;   
    font-weight: 400;
    padding: 5px 0;
    border-bottom: 1px solid  #D3D3D3;
   }
.checkoutCard .card-body .classSec {
    padding: 20px 0;
    border-bottom: 1px solid  #D3D3D3;
    border-top: 1px solid  #D3D3D3;
}

.checkoutCard .card-body .classSec h4{
    color: #20C868;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.checkoutCard .card-body .classSec div{
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}
.checkoutCard .card-body .classSec div p{
    display: flex;
    align-items: center;
    gap: 5px;
}

.couponSec {
    padding: 20px 0;
}
.couponSec h3{
    color: #1F1F39;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}
.promoCodeSec {
    display: flex;
    gap: 10px;
}
.promoCodeSec input{
    background: #EEF4F6;
    width: 100%;
    border: 0;
    outline: 0;
    padding: 15px 10px;
}
.promoCodeSec button{
    max-width: 140px;
    width: 100%;
    border: 1px solid #20C868;
    background-color: transparent;
    color: #20C868;
    text-align: center;
    font-size: 17.531px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.discountSec p {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.50);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
}
.discountSec p span{
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    color: #000;
}
.durationTime p {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.50);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;
}
.durationTime p span{
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}
.totalPaymentSec{
    display: flex;
    padding: 10px 25px;
    border-top: 1px solid  #D3D3D3;
    border-bottom: 1px solid  #D3D3D3;
    justify-content: space-between;
}
.totalPaymentSec h2{
    color: #1F1F39;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.paybtnSec{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}
.paybtnSec button{
    background: #20C868;
    max-width: 180px;
    width: 100%;
    padding: 10px 12px;
    border: 0;
    color: #FFF;
    text-align: center;
    font-family: Open Sans;
    font-size: 17.531px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.studentClassesSec{
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 64px 0;
}
.studentClassesCard {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 15px;
}
.studentClassesCard .cardTitle{
    color: #1F1F39;    
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.studentClassesCard .dateTimeSection {
    display: flex;
    gap: 40px;
}

.studentClassesCard .dateTimeSection p img{
    position: relative;
    top: 1px;
    margin-right: 5px;
}
.studentClassesCard .dateTimeSection .boldText{
    color: #1F1F39;   
    font-size: 20px;    
    font-weight: 600;    
    /* padding-left: 20px; */
}
.studentClassesCard .payNowSec{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}
.studentClassesCard .payNowSec .statusSec p{
    font-size: 16px;    
}
.studentClassesCard .payNowSec .paynowbtn{
    background: #23CE6B;
    padding: 10px;
    color: #FFF;    
    font-size: 18.254px;    
    font-weight: 600;    
    border: 0;
}
.studentClassesCard .payNowSec .statusSec.start p{
    border-radius: 51px;
    background: rgba(2, 71, 81, 0.15);
    padding: 5px 15px;
} 
.studentClassesCard .payNowSec .statusSec.failed p{
    border-radius: 51px;
    background: rgba(235, 0, 41, 0.15);
    padding: 5px 15px;
     color: #EB0029;
} 
.studentClassesCard .payNowSec .statusSec.inProgress p{
    border-radius: 51px;
    padding: 5px 15px;
     color: #EB0029;
    background: rgba(247, 152, 29, 0.15);
} 
.studentClassesCard .payNowSec .statusSec.success p{
    border-radius: 51px;
    padding: 5px 15px;
    color: #23CE6B;
    background: rgba(35, 206, 107, 0.15);
} 
.otpVerification .otpPopUP{
    position: fixed;
    background: rgba(0, 0, 0, 0.80);
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    left: 0;
    top: 0;
}
.otpVerification .otpPopUP .otpPopUPCard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    max-width: 570px;
    width: 100%;    
}
.addClassCard .card-Heading {
    color: #1F1F39;    
    font-size: 22px;    
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}

.otpPopUPCard .card-Heading{
    color: #1F1F39;   
    font-size: 20px;    
    font-weight: 600;  
    text-align: center; 
    margin: 15px 0;
}
.otpPopUPCard .card-body{
    padding: 20px;
    text-align: center;
}
.otpPopUPCard .card-body .titleImgSec{
    width: 100%;
    text-align: center;
}

.otpPopUPCard .contactInfo p{
    color: #1F1F39;    
    font-size: 16px;    
    font-weight: 600;
    
}
.mobileForm{
    padding: 25px 0;
}
.countryCode {
    display: flex;
    justify-content: center;
    align-items: center;
}
.countryCode select{
    background: var(--Five, #EEF4F6);
    padding: 13px 10px 13px 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-right: 1px solid var(--Four, #C9D8DA);
    outline: 0;
}
.countryCode .mobilenumber input{
    background: var(--Five, #EEF4F6);
    padding: 13px 10px 13px 11px;
    border: 0;
    outline: 0;
}
.sendedCode{
    padding: 10px 0;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.sendedCode p{
    color: #1F1F39;
    font-size: 16px;
}
.sendedCode a{
    color: var(--Two, #23CE6B);
    font-size: 16px;
    text-decoration: none;
}

.otpEnterSec {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.otpEnterSec input{
    display: flex;
    width: 48px;
    height: 48px;
    padding: 13px 13px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #EEF4F6;
    border: 0;
    outline: 0;
    text-align: center;
}

.notReceiveCode{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 15px 0;
}
.notReceiveCode p{
    color: rgba(0, 0, 0, 0.50);    
    font-size: 16px;
}
.notReceiveCode a{
    color: var(--Two, #23CE6B);
    font-size: 16px;
    text-decoration: none;
}

.cardFooter {
    padding: 20px ;
    border-top: 0.5px solid #B9B9B9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardFooter .verifyBtn{
    background-color: #20C868;
    max-width: 180px;
    width: 100%;
    border: 0;
    padding: 11px 15px;
    color: #FFF;
    text-align: center;    
    font-size: 17.531px;
}
.otpVerification .closeBtn {
    position: absolute;
    right: -27px;
    width: 25px;
    height: 25px;
    background: transparent;
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border-radius: 50%;
    padding: 2px;
    display: flex;
    justify-content: center;
}