/* End Reset Css */
* {
  box-sizing: border-box;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.text-center {
  text-align: center !important;
}

.app-container {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
}
.checkoutPageSec .app-container{
  padding: 50px 0 0;
}
.form-centered-wrap {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #EEF4F6;
}
.form-centered-wrap .form-cw-inner {
  align-self: center;
  width: 100%;
  max-width: 800px;
  position: relative;
  z-index: 2;
  padding: 30px 0;
}
.form-centered-wrap .form-wrapper {
  align-self: center;
  max-width: 600px;
  position: relative;
  z-index: 2;
  padding: 30px 0;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  color: #1F1F39;
}

.mb-15 {
  margin-bottom: 15px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}

.mt-15 {
  margin-top: 15px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

/* End Reset Css */

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3A3A3A;
  min-height: 100vh;
  background: #eef4f6;
}

body.lang-arb {
  direction: rtl;
}
.title-1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;
}
.logoHeader{
  max-width: 200px;
  width: 100%;
}
.languagesBtn {
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  gap: 30px;
}
.languagesBtn .lngBtn{
  color: #20C868;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-decoration: none;
  border: 1px solid #20C868;
  max-width: 150px;
  width: 100%;
  position: relative;
  border-radius: 3px;
}
.languagesBtn .lngBtn input{
  position: absolute;
  display: none;
}
.languagesBtn .lngBtn span {
  text-align: center;
  display: block;
  padding: 10px 15px ;
  cursor: pointer;
}

.languagesBtn .lngBtn input:checked + span {
  color: #ffffff;
  background-color: #20C868;
}
/* .languagesBtn .engBtn{
  background-color: #20C868;
  color: #fff;
  transition: all 0.3s;
}
.languagesBtn .engBtn:hover{
  background-color: transparent;
  color: #20C868;
  transition: all 0.3s;
}

.languagesBtn .arbbtn{
  background-color: transparent;
  transition: all 0.3s;
}
.languagesBtn .arbBtn:hover{
  background-color: #20C868;
  color: #fff;
  transition: all 0.3s;
} */
.logoHeader img{
  width: 100%;
  height: 100%;
}

.info-text {
  font-size: 24px;
}
.logoSec {
  max-width: 230px;
  width: 100%;
  margin: 0 auto;
}
.logoSec img{
  width: 100%;
  height: 100%;
}
.form-design input[type="text"],
.form-design input[type="password"] {
  background-color: #ffffff;
  background: #ffffff;
}
.more-login-option {
  position: relative;
  display: flex;
}
.more-login-option:before, .more-login-option:after {
  content: "";
  flex: 1 1 48%;
  max-width: 48%;
  height: 1px;
  background-color: #B8B8D2;
  align-self: center;
  opacity: 0.5;
}
.more-login-option span {
  display: inline-block;
  background-color: #ffffff;
  padding: 5px 20px;
  flex: 0 0 auto;
  width: auto;
  margin: 0 5px;
}
button.MuiButtonBase-root {
  text-transform: none !important;
  margin-right: 8px;
}
.bg-element-1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  pointer-events: none;
}
.bg-element-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}
.login-as-box {
  display: inline-block;
  text-align: center;
  border: 1px solid rgba(2, 71, 81, 0.20);
  padding: 35px 20px;
  width: 250px;
  background-color: #ffffff;
  text-decoration: none !important;
}
.login-as-box p {
  color: #000;
text-align: center;
font-size: 28px;
font-weight: 700;
margin: 15px 0 0;
}
.login-as-box:hover {
  border: 1px solid #20C868;
  background: #FFF;
  box-shadow: 0px 3px 12px 0px rgba(32, 200, 104, 0.40);
}
.login-as-box:hover p {
  color: #20C868;
}
.login-options-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
.form-design-2 {
  
}
.form-design-2 input[type=text],
.form-design-2 input[type=number],
.form-design-2 .MuiSelect-select {
  font-weight: 600;
  color: #3A3A3A;
  padding-top: 10px;
  padding-bottom: 10px;
}
.radio-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.radio-group .custom-radio {
  margin: 0 15px 15px;
}
.custom-radio {
  position: relative;
  cursor: pointer;
}
.custom-radio>input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}


.custom-radio > span {
  display: inline-block;
  padding: 6px 25px;
  font-size: 18px;
  color: #a3a3a3;
  border: 1px solid #024751;
  background-color: #ffffff;
  font-weight: 500;
}
.custom-radio > input[type="radio"]:checked + span,
.custom-radio > input[type="checkbox"]:checked + span {
  background-color: rgba(32, 200, 105, 0.1);
  border-color: #20c868 !important;
  color: #20c868 !important;
}
.age-group-wrap .custom-radio>span {
  min-width: 210px;
  text-align: center;
}
.fs20 {
  font-size: 20px;
  font-weight: 600;
}
.fs24 {
  font-size: 24px;
}
.fs28 {
  font-size: 28px;
  font-weight: 700;
}
.text-btn {
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
  color: #000000 !important;
}
.form-design .MuiCheckbox-root {
  align-self: flex-start !important;
  padding-top: 2px !important;
}

.class-select-list .custom-radio>span {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 86px;
  text-align: center;
}
.subject-select-list .custom-radio>span {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 115px;
  text-align: center;
}

.checklist .MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}
.checklist label {
  margin-bottom: 10px;
}
.page-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 20px 0;
  background: #EEF4F6;
}

.loggedin-profile {
  display: inline-block;
  position: relative;
  z-index: 0;
  padding: 0px 60px 0px 5px;
  text-align: right;
  min-height: 50px;
}

.loggedin-profile>img {
  width: 50px;
  height: 50px;
  border-radius: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
}

.loggedin-profile h3 {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.2;
  margin-bottom: 5px;
}

.loggedin-profile p {
  font-size: 13px;
  color: #ffffff;
}
.main-header {
  background-color: #024751;
  padding: 15px 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.livetutors-count {
font-size: 16px;
}
.livetutors-count .MuiSvgIcon-root {
  display: inline;
  vertical-align: middle;
  line-height: 1;
  font-size: 140%;
}
.livetutors-count>span {
  color: #EB0029;
}
.fs18 {
  font-size: 18px;
}
.fs16 {
  font-size: 16px;
}
.white-card {
  background: #ffffff;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.16);
  padding: 15px;
}
.white-card-erning{
  display: flex;
  justify-content: space-around;
}
.seacrh-wrapper .MuiInputBase-root {
  background: #EEF4F6;
}
.seacrh-wrapper fieldset {
  border-color: #EEF4F6;
}
.info-img-card{
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #efefef;
  background: #ffffff;
  margin: 25px 0;
}
.info-img-card .content-wrap {
  flex: 0 0 50%;
  max-width: 50%;
}
.info-img-card .img-wrap {
  flex: 0 0 50%;
  max-width: 50%;
}
.info-img-card .img-wrap img {
  width: 100%;
}
.info-img-card.bg-green {
  background-color: #23CE6B;
}
.info-img-card.bg-green h2 {
  color: #ffffff;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fav-teacher-card {
  position: relative;
}

.fav-teacher-card .img-holder {
  position: relative;
  z-index: 1;
}
.fav-teacher-card .img-holder .ft-info-bottom {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  bottom: 15px;
  left: 0;
  width: 100%;
}
.fav-teacher-card .img-holder .ft-info-bottom img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 4px;
}

.fav-teacher-card .img-holder .ft-info-bottom span {
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px 2px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #024751;
  font-weight: 700;
}
.fav-teacher-card .img-holder img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}
.fav-teacher-card .fav-content {
  
}
.fav-teacher-card .fav-content h2 {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 10px;
}

.online-status {
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 20px;
  border: 2px solid #ffffff;
  width: 16px;
  height: 16px;
  background: #BBBBBC;
  display: inline-block;
  border-radius: 50%;
}
.online-status.online_ {  
  background: #20C868;
}
.online-status.offline_ {  
  background: #EB0029;
}
.btn-1 {
  text-transform: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.info-img-card.iic-2 {
  padding: 15px 30px;
}
.info-img-card.iic-2 .img-wrap img {
  max-width: 230px;
}

.info-img-card.iic-2 .img-wrap {
  text-align: right;
  align-self: end;
  position: relative;
  top: 15px;
  display: inline-flex;
  justify-content: center;
}
.info-img-card.iic-2.iic-bg-1 {
  /* background-color: #024751; */
}
.info-img-card.iic-2.iic-bg-2 {
  background-color: #39D37A;
}
.info-img-card.iic-2 .fs28 {
  margin-bottom: 10px;
}
.info-img-card.iic-2 .fs28, .info-img-card.iic-2 p {
  color: #ffffff;
}
.subject-card {
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 20px;
  border: 1px solid #ffffff
}

.subject-card .img-wrap img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.subject-card h2 {
  font-size: 18px;
  font-weight: 400;
}

.subject-card .img-wrap {
  margin-bottom: 10px;
}

.connect-to-tuter-sec {
  padding-top: 30px; 
  padding-bottom: 30px; 
}
.tab-design {
  margin-top: 20px;
}
.tab-design .MuiTabs-flexContainer .MuiButtonBase-root {
  background: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 15px;
}

.tab-design .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
  background: #024751;
  color: #ffffff;
}

.tab-design .MuiTabs-indicator {
  display: none;
}

.tutor-card {
  display: flex;
  background: #ffffff;
  padding: 25px;
  margin: 25px 0;
}

.tutor-card .img-box {
  flex: 0 0 120px;
  max-width: 120px;
  position: relative;
  display: inline-flex;
}
.tutor-card .img-box img {
  height: 120px;
  object-fit: cover;
}

.tutor-card .box-content {
  flex: 0 0 calc(100% - 120px);
  max-width: calc(100% - 120px);
  padding-left: 20px;
  padding-right: 0px;
  display: flex;
}
.tutor-card .box-content p {
  font-size: 14px;
}
.rating-info {
  display: flex;
}
.rating-info .MuiRating-root {
  margin-left: 5px;
  font-size: 20px;
  align-self: center;
  line-height: 1;
}
.tutor-card .box-content .MuiStack-root {
  margin-top: 5px;
}
.tutor-card .box-content .MuiChip-root {
  background: #EEF4F6;
}
.tutor-card .box-content .bc-left {
  flex: 1 1 auto;
}
.tutor-card .box-content .bc-right {
  flex: 0 0 auto;
}
.subject-checkwrap {
  position: relative;
  z-index: 1;
}
.subject-checkwrap input[type='checkbox'] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 0;
  margin: 0;
}
.subject-checkwrap .custom-checkbox {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
}
.subject-checkwrap, .subject-checkwrap .subject-card, .subject-checkwrap input[type='checkbox'] {
  cursor: pointer;
}
.subject-checkwrap .subject-card .custom-checkbox {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  border: 1px solid #024751;
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
.subject-checkwrap input[type='checkbox']:checked + .subject-card .custom-checkbox:after {
  content: "";
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: 2px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
  position: absolute;
}
.subject-checkwrap input[type='checkbox']:checked + .subject-card .custom-checkbox {
  background-color: #024751;
}
.subject-checkwrap input[type='checkbox']:checked + .subject-card .custom-checkbox {
  opacity: 1;
}

.subject-checkwrap input[type='checkbox']:checked + .subject-card {
  border-color: #024751;
}
.p-0 {
  padding: 0;
}
.innner-top-header {
  background-color: #ffffff;
  padding: 20px 0;
}
.innner-top-header .ith-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.txt-btn {
  cursor: pointer;
}
.filter-btn {
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.filter-wrapper {
  padding: 30px;
}
.select-filter-item {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.select-filter-item .filter-select {
  margin: 0 8px 15px;
  cursor: pointer;
}
.filter-select {
  position: relative;
  z-index: 1;
}
.filter-select input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.filter-select .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  background-color: #eee;
  padding: 8px 20px;
  border-radius: 0px;
  font-size: 14px;
}

/* On mouse-over, add a grey background color */
.filter-select:hover input ~ .checkmark {
  background-color: #EEF4F6;
}

/* When the checkbox is checked, add a blue background */
.filter-select input:checked ~ .checkmark {
  background-color: #024751;
  color: #ffffff;
}

/* Show the checkmark when checked */
.filter-select input:checked ~ .checkmark:after {
  display: block;
}
.MuiDrawer-root.drawer-design .MuiDrawer-paper {
  max-width: 800px;
  margin: 0 auto;
}
.filter-select-items {
  margin-bottom: 30px;
}
.m-0 {
  margin: 0;
}

.drawer-title {
  position: sticky;
  top: 0;
  background: #024751;
  padding: 15px 30px;
  z-index: 2;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.drawer-title h2 {
  color: #ffffff;
}
.text-white {
  color: #ffffff !important;
}
.filter-wrapper .btn-wrap button {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.info-msg-model {
  text-align: center;
}

.connecting-to-tutor-model .MuiDialogContent-root {
  background: #024751;
  color: #ffffff;
  padding: 30px;
}

.connecting-to-tutor-model .MuiDialogContent-root p, 
.connecting-to-tutor-model .MuiDialogContent-root h2,
.connecting-to-tutor-model .MuiDialogContent-root h3 {
  color: #ffffff;
}
.connecting-info-model {
  
}
.connecting-info-model .tutor-img {
  text-align: center;
}
.connecting-info-model .tutor-img img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 5px solid #356c74;
  margin: 0 auto;
}
.connecting-info {
  margin: 25px 0;
}
.connecting-info p {
  font-style: 26px;
  color: #ffffff;
  font-weight: 500;
}

.connecting-to-tutor-model .MuiDialog-paperScrollPaper,
.info-msg-model-wrap .MuiDialog-paperScrollPaper,
.sessionEndModel-model .MuiDialog-paperScrollPaper {
  max-width: 500px !important;
  width: 100%;
}
button.btn-white {
  background: #ffffff;
  color: #767676;
}

button.btn-white:hover {
  color: #ffffff;
}
.connecting-info-model .cim-header {
  margin-bottom: 30px;
}
.connecting-info-model .connecting-info {
  text-align: center;
}
.connecting-info-model .connecting-info span {
  font-size: 50px;
  line-height: 1.2;
}
.text-green {
  color: #20c868 !important;
}
.text-danger {
  color: #EF4623 !important;
}
.sessionEndModel-model {

}
.session-box-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.session-box {
  margin: 0 0 10px;
  display: inline-block;
  flex: 0 0 33.33%;
  padding: 0 10px;
}

.session-box-info p {
  font-size: 14px;
}

.session-box-info h3 {
  font-size: 26px;
  font-weight: 700;
  margin: 5px 0 0;
  color: #464646;
}

.info-msg-sessionEndModel {
  margin-top: 30px;
}
.info-msg-sessionEndModel .session-box-wrap {
  margin: 20px 0;
}
.session-box-info {
  text-align: center;
}

.session-box:not(:last-child) {
  border-right: 1px solid #e1e1e1;
}
.session-box-info h3 span {
  font-size: 13px;
  font-weight: 400;
}
.model-teacher-rating {
  text-align: center;
  margin-bottom: 30px;
}
.session-box-info h3 span {
  font-size: 13px;
}
.model-teacher-rating span.MuiRating-root {
  font-size: 35px;
}
.account-top-header {
  background: #024751;
  padding: 20px;
}
.profile-card {
  text-align: center;
}
.icon-list .MuiListItemIcon-root {
  min-width: 40px;
}
.profile-card .pc-content {
  padding-left: 20px;
}

.profile-card {
  display: flex;
  align-items: end;
  text-align: left;
}

.profile-card .pc-content h2 {
  font-size: 30px;
  color: #ffffff;
  font-weight: 600;
}

.profile-card .pc-content p {color: #ffffff;}

.profile-card img {
  width: 100px;
  height: 120px;
  object-fit: cover;
  border: 1px solid #ffffff;
}
.total-earning-box {
  text-align: center;
  padding: 20px 0px;
}
.total-earning-box .fs24 {
  color: #024751;
}
.total-earning-box p {
  font-size: 32px;
  font-weight: 600;
}
.title-wrap {
  display: flex;
  margin-bottom: 30px;
}
.title-wrap h2 {
  flex: 1 1 auto;
}
.transaction-card {
  
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 30px;
}
.transaction-card .tc-icon {
  align-self: flex-start;
  flex: 0 0 56px;
  max-width: 56px;
  height: 50px;
  background: #C9D8DA;
  text-align: center;
  padding: 10px 0;
  line-height: 1;
  margin-right: 15px;
}
.transaction-card .tc-icon svg {
  font-size: 24px;
  color: #024751;
}
.transaction-bottom{
  display: flex;
  justify-content: space-between;
  padding: 15px 0 5px;
}
.transaction-bottom .tccFinalStatus{
  color: #00a146;
  font-weight: 600;
}

.transaction-bottom .tccFailedStatus{
  color: #FF2020;;
  font-weight: 600;
}

.transaction-bottom .tccPendingStatus{
  color: #F97D08;;
  font-weight: 600;
}
.transaction-bottom .tcc-info span{
  font-weight: 600 ;
}
.transaction-card .tc-content {
  flex: 0 0 calc(100% - 56px);
  -webkit-flex: 0 0 calc(100% - 56px);
  display: flex;
}

.transaction-card .tc-content .tcc-left-wrap {
  flex: 1 1 auto;
}

.transaction-card .tc-content .tcc-right-wrap {
  flex: 0 0 auto;
}
.color-2 {
  color: #024751;
}
.transaction-card .tc-content .tcc-right-wrap .tccr-rating {
  display: inline-flex;
}
.transaction-card .tc-content .tcc-right-wrap .tccr-rating svg {
  color: #FFCE31;
}
.transaction-card .tc-content .tcc-left-wrap .tcc-info .tcci-box {
  display: inline-block;
  margin-right: 35px;
}
.transaction-card .tc-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.color-1 {
  color: #009F2D;
}

.transaction-card .tc-bottom p {
  font-size: 14px;
}
.add-bank-page {
  padding: 40px 0;
}
.innner-top-header .ith-wrap h2 {
  display: inline-flex;
  align-items: center;
}
.welcome-box {
  background-color: #3B79BE;
  padding: 60px 40px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.welcome-box {

}

.welcome-box h2,
.welcome-box p {
  color: #ffffff;
}
.welcome-box h2 {
  margin-bottom: 10px;
}
.icon-info-box {
  background: #ffffff;
  padding: 16px;
}
.icon-info-box .icon-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.icon-info-box .content-wrap h2 {
  color: #717171;
}

.icon-info-box .icon-wrap span {
  font-size: 32px;
  font-weight: 600;
}
.subject-card-2 {
  background: #ffffff;
  margin-top: 20px;
}

.subject-card-2 .sc-header {
  border-bottom: 1px solid #EBEBEB;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.subject-card-2 .sc-header .sch-icon {
  flex: 0 0 40px;
  max-width: 40px;
}

.subject-card-2 .sc-header 
.sch-content {
  flex: 0 0 calc(100% - 40px);
  max-width: calc(100% - 40px);
  padding-left: 15px;
}

.subject-card-2 .sc-header .sch-icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.subject-card-2 .sc-tutor {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.subject-card-2 .sc-tutor .sc-tutor-info {
  display: flex;
  align-items: center;
}

.subject-card-2 .sc-tutor .sc-tutor-info img {

}
.subject-card-2 .sc-tutor .sc-tutor-info>img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.subject-card-2 .sc-tutor .sc-tutor-info .sc-tutor-info-text {
  padding-left: 15px;
}

.subject-card-2 .sc-tutor .sc-tutor-info .sc-tutor-info-text h3 {
  font-size: 16px;
  font-weight: 600;
}

.subject-card-2 .sc-tutor .sc-tutor-info .sc-tutor-info-text p {
  font-size: 14px;
}

.subject-card-2 .sc-header .sch-content p {
  font-size: 14px;
}
.subject-card-2 .sc-tutor .sc-tutor-acrion {
  display: flex;
  align-items: center;
}
button.btn-close {
  background: rgba(255, 255, 255, 0.12);
  width: auto;
  padding: 3px;
  min-width: auto;
  border-radius: 50%;
  color: #ffffff;
}
.welcome-box .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
}
.sessionEndModel-model.for_student .info-msg-sessionEndModel .session-box-wrap .session-box {
  flex: 0 0 50%;
  max-width: 50%;
  border-style: none;
}
.sessionEndModel-model.for_student .info-msg-sessionEndModel .session-box-info {
  text-align: left;
}
.connecting-to-tutor-model .model-btn-wrap {
  text-align: center;
  margin: 0 -10px;
}
.connecting-to-tutor-model .model-btn-wrap button {
  margin: 0 10px;
}
.loader-img {
  text-align: center;
  margin: 20px 0;
}
.loader-img img {
  margin: 0 auto;
  -webkit-animation: rotate 5s normal linear infinite;
  animation: rotate 5s normal linear infinite;
  width: 40px;
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
.tutor-img-wrap {
  text-align: center;
  margin-top: 30px;
}
.tutor-img-wrap img {
  width: 80px;
  height: 80px;
}
.account-top-header .MuiRating-sizeMedium svg {
  color: #faaf00;
}
.web-list  {
  list-style: none;
  padding: 0;
  margin: 16px 0;
}
.web-list li {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 10px 0;
}
.web-list li:not(:last-child) {
  border-bottom: 1px solid rgb(218, 218, 218);
}

.profile-card.pc-design-2 {
  border: 1px solid #aeaeae;
  padding: 20px;
  background: #ffffff;
  margin: 30px 0;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.16);
}

.profile-card.pc-design-2 .pc-content h2 {
  color: #1F1F39;
}

.profile-card.pc-design-2 .pc-content p {
  color: #1F1F39;
}

.profile-card.pc-design-2 img {
  border: 1px solid #cdcdcd;
}
.d-block {
  display: block;
}
.no-drop-icon .MuiSelect-iconOutlined,
.no-drop-icon [data-testid="ArrowDropDownIcon"] {
  display: none;
}
.btn-right-box {
  display: flex;
  flex-wrap: wrap;
}
.btn-right-box .brb-right {
  flex: 0 0 110px;
  max-width: 110px;
}
.btn-right-box .brb-left {
  flex: 0 0 calc(100% - 110px);
  max-width: calc(100% - 110px);
}
.btn-right-box .MuiOutlinedInput-root {
  background-color: #ffffff;
}
.icon-list-1 .MuiListItemIcon-root {
  min-width: 30px;
}
.icon-list-1 .MuiListItem-root {
  padding-left: 0;
  padding-right: 0;
}
.icon-list-1 .icon-red {
  color: red;
  cursor: pointer;
}
.icon-list-1 .MuiListItem-root:not(:last-child) {
  border-bottom: 1px solid #b7ccd0;
}
.list-input-box {
  display: inline-block;
  padding: 6px 5px;
  width: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  text-align: center;
  margin: 0 5px;
}
.googleBtn {
  background-color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
}

.appleBtn {
  background-color: #000000;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
}
.perHourCnt {
  position: relative;
  padding-right: 40px;
}
.perHourCnt .cnt {
  display: inline-block;
  text-align: left;
  position: absolute;
  top: 2px;
}
.successpopUpTitle .priceTitle{
  display: block;
  color: #1F1F39;
  text-align: center;
  font-family: Open Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.successpopUpTitle .successTitle{
  color: #1F1F39;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.info-msg-model .successpopUpTitle{
  margin-bottom: 80px;
}
.info-msg-model{
  position: relative;
}
.info-msg-model .rocketImg{
  position: absolute;
  top: 0;
  right: 0;
}
.info-msg-model .bookImg {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 200px;
  width: 100%;
}
.bookTitle{
  color: var(--foundation-grey-grey-500, #323232);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 81.25% */
  margin-bottom: 20px;
}
.homePageSec {
  background: #024751;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
} 
.homePageContent{
  max-width: 300px;
  width: 100%;
  position: relative;
  z-index: 1;
}
.ellipseOne {
  position: absolute;
  right: 0;
  max-width: 500px;
  width: 100%;
  top: 0;
  object-fit: cover;
}
.ellipseTwo {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  
  object-fit: cover;
}
.ellipseThree{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
 
  object-fit: cover;
}
.tutorHeadingSec {
  padding: 0 15px;
}


/* ltr to rlt start */
.lang-arb .appleBtn {
  margin: 0 15px 0 0;
}
.lang-arb .tutor-card .box-content .bc-left{
  margin-right: 15px;
}
.lang-arb .subject-checkwrap .custom-checkbox{
 
  left: 10px;
  right: auto;
}
.lang-arb .subject-checkwrap .subject-card .custom-checkbox{
  left: 10px ;
}
.lang-arb .transaction-card .tc-icon{
  margin: 0 0 0 15px;
}
/* ltr to rlt End */





@media screen and (max-width:991px){
  .app-container{
    padding: 0 15px;
  }
}
@media screen and (max-width:767px){
  .title-1 {
    font-size: 32px;
  }
  .info-text {
    font-size: 20px;
  }
  .main-header{
    padding: 10px 15px;
  }
  .logoHeader {
    max-width: 150px;
    width: 100%;
  }
  .loggedin-profile h3{
    font-size: 18px;
  }
  .loggedin-profile p {
    font-size: 14px;    
  }
  .transaction-card .tc-content .tcc-left-wrap .fs20{
    font-size: 16px;
  }
  .transaction-card .tc-content .tcc-left-wrap .transc-info{
    font-size: 14px;
  }
  .transaction-card .tc-content .tcc-right-wrap .fs24{
    font-size: 20px;
  }
  .transaction-bottom{
    font-size: 14px;
  }
} 
@media screen and (max-width:560px){
  .tutor-card{
    flex-direction: column;
    gap: 15px;
  }
  .tutor-card .box-content{
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  .tutor-card .box-content .bc-left .fs20{
    font-size: 16px;
  }
}
@media screen and (max-width:390px){
  .loggedin-profile h3{
    font-size: 14px;
  }
  .loggedin-profile p {
    font-size: 10px;    
  }
  .loggedin-profile>img {
    width: 40px;
    height: 40px;
  }
  .loggedin-profile{
    padding: 2px 50px 0px 5px;
  }
}


.grade-container {
  display: flex;
  flex-direction: row; /* This sets the direction to horizontal */
}

.grade-item {
  margin-right: 10px; /* Adjust this value to control spacing between items */
}

.error-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.error-modal .error-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  max-width: 400px;
  width: 70%;
}
.error-modal .error-modal-content .MuiSvgIcon-root {
  font-size: 60px;
}

.cis-modal-wrap {
  text-align: center;
  padding: 10px 0;
}

.cis-modal-wrap p {
  margin-bottom: 0;
  font-size: 20px;
}

/* The Close Button */
.error-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.error-modal-close:hover,
.error-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.search-btn {
  display: flex !important;
  justify-content: flex-start !important;
  background: #EEF4F6 !important;
  height: 56px;
  color: rgba(0, 0, 0, 0.60) !important;
  font-size: 16px !important;
  gap: 13px  !important;
}

.no-tutor {
  margin-top: 43px;
  border: 1px dotted black;
  border-radius: 5px;
  padding: 51px;
  text-align: center;
  background: #ffffff;
}

.try-again {
  background-color: #EF4623 !important;
}

.online-status.off_ {
  background: #BBBBBC;
}

.tutor-name {
  display: block;
  width: 100px;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  max-height: 22px;
}

.rightMEnu-wrap {
  position: relative;
}

.d-inline {
  display: inline;
}
.fav-btn {
  position: absolute !important;
  left: 5px;
  bottom: 6px;
  color: #ff670b !important;
  background-color: #ffffff !important;
  padding: 5px !important;
}
.fav-btn svg {
  font-size: 18px;
}
.login-as-box img {
  margin: 0 auto;
}

.connecting-info-model h4 {
  color: #27e4ff;
}
.tutor-img-wrap  img {
  margin: 0 auto;
}
.info-msg-model-wrap .info-msg-model .popHeadingImg  img {
margin: 0 auto;
}
.topMenuList li a {
  color: inherit;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 16px !important;
  background: #ddecf0;
  padding: 6px 9px;
}
.info-img-card.iic-2.iic-bg-1 .content-wrap {
  flex: 0 0 100%;
  max-width: 100%;
}

.info-img-card.iic-2.iic-bg-1 {
  padding: 0;
}
.w-100 {
  width: 100%;
}
.checkoutPageSec {
  display: block;
  background: #eef4f6;
}

.profile-card.pc-design-2 {
  margin: 0;
  border: 1px solid #eef4f6;
}
.checkoutPageSec + .page-wrapper {
  padding-top: 0;
}

.checkoutPageSec .profile-card.pc-design-2 {
  border-bottom: 1px solid #d2dbde;
}
.filter-select-items .MuiFormControl-root .MuiInput-underline:before {
  border-color: #9fb9bd;
  border-bottom-width: 4px;
}
.filter-select-items .MuiSelect-select {
  padding-top: 10px;
  padding-bottom: 10px;
}
.swiper-slide .info-img-card {
  padding: 0;
}
.subject-checkwrap input[type='checkbox']:checked + .subject-card .custom-checkbox:after {
  top: 3px;
  left: 6px;
}

.connecting-to-tutor-model  .MuiRating-iconEmpty {
  color: #ffffff !important;
}

.tutor-search-image {
  width:100% !important;
}

.swiper-button-next {
  color:black !important;
}

.swiper-button-prev {
  color:black !important;
}

.tutor-nationality {
  margin-right: 58px !important;
}

.slider-active {
  font-weight: 900;
  font-size: 20px;
  color: #13ca6f;
}

.slider-inactive {
  font-weight: 900;
  font-size: 20px;
  color: #f10116;
}

.google-container {
  width: 300px;
}
