.loader {
    color: white;
    font-size: 40px;
    font-weight:600;
    /* letter-spacing: 1px; */
    text-shadow: 5px 3px 3px black ;
  }

.loading {
  color: white;
  font-size: 40px;
  font-weight:600;
  letter-spacing: 1px;
  white-space: nowrap;
  text-shadow: 5px 3px 3px black ;
}